import axios from "./axios";
import {
  AlertHistory,
  CalibrationData,
  CalibrationLog,
  ChartItem,
  ControlAlgorithmLog,
  ControlAlgorithmTestRequest,
  CreateDeviceRequest,
  CurrentData,
  DeviceCharts,
  DeviceCurrentData,
  DeviceLogResponse,
  DeviceStatusT,
  DeviceSummary,
  UpdateDeviceAlertParametersRequest,
  UpdateDeviceCalibrationSchedulerRequest,
  UpdateDeviceCanisterContentRequest,
  UpdateDeviceRequest,
  UpdateDeviceSchedulerRequest,
  UpdateDeviceStatusRequest,
  ValveHistory,
  DeviceSample,
  UpdateSampleVisibilityRequest,
  PressureMonitorCharts,
  WeatherStationCurrentData,
  WeatherStationCharts,
  ApisDeviceT,
  AlertParameterT,
  SampleSummary,
  DeviceMonthlyPerformanceSummary
} from "@apis/types";
import { saveAs } from "file-saver";
import { showNotification } from "@mantine/notifications";
import { AsyncOperationDeviceReport, CustomerData, PerformanceModel, AsyncOperationReportType, DeviceSoC } from "@apis/dynamodb";

export const getDevicesOfLandfill = async (landfill: string): Promise<ApisDeviceT[]> => {
  if(!landfill) return [];
  const res = await axios.get<ApisDeviceT[]>("/devices", { params: { landfill } });
  return res.data;
}

export const getDevice = async (deviceID: string): Promise<ApisDeviceT> => {
  const res = await axios.get<ApisDeviceT>(`/devices/${deviceID}`);
  return res.data;
}

export const getDevicesByKeyword = async (keyword: string): Promise<ApisDeviceT[]> => {
  if (keyword?.length < 3) return []
  const res = await axios.get<ApisDeviceT[]>("/devices/search", { params: { keyword } });
  return res.data;
}

export const createDevice = async (body: CreateDeviceRequest): Promise<ApisDeviceT> => {
  const res = await axios.post<ApisDeviceT>("/devices", body);
  return res.data;
}

export const updateDevice = async (deviceID: string, body: UpdateDeviceRequest): Promise<ApisDeviceT> => {
  const res = await axios.put<ApisDeviceT>(`/devices/${deviceID}`, body);
  return res.data;
}

export const deleteDevice = async (deviceID: string): Promise<ApisDeviceT> => {
  const res = await axios.delete<ApisDeviceT>(`/devices/${deviceID}`);
  return res.data;
}

export const getDeviceStatus = async (deviceID: string): Promise<DeviceStatusT> => {
  const res = await axios.get<DeviceStatusT>(`/devices/${deviceID}/status`);
  return res.data;
}

export const getDeviceStatusHistory = async (deviceID: string): Promise<DeviceStatusT[]> => {
  const res = await axios.get<DeviceStatusT[]>(`/devices/${deviceID}/status-history`);
  return res.data;
}

export const updateDeviceStatus = async (deviceID: string, body: UpdateDeviceStatusRequest): Promise<DeviceStatusT> => {
  const res = await axios.put<DeviceStatusT>(`/devices/${deviceID}/status`, body);
  return res.data;
}

export const updateDeviceScheduler = async (deviceID: string, body: UpdateDeviceSchedulerRequest): Promise<ApisDeviceT> => {
  const res = await axios.put<ApisDeviceT>(`/devices/${deviceID}/scheduler`, body);
  return res.data;
}

export const getDeviceVersion = async (deviceID: string): Promise<{ version: number }> => {
  const res = await axios.get<{ version: number }>(`/devices/${deviceID}/version`);
  return res.data;
}

export const adjustValvePosition = async (deviceID: string, value: number): Promise<{ message: string, status: boolean }> => {
  const res = await axios.post<{ message: string, status: boolean }>(`/devices/${deviceID}/adjust-valve/${value}`);
  return res.data;
}

export const takeDeviceSample = async (deviceID: string): Promise<{ message: string }> => {
  const res = await axios.post<{ message: string }>(`/devices/${deviceID}/sample`);
  return res.data;
}

export const updateDeviceAlertParameter = async (deviceID: string, body: UpdateDeviceAlertParametersRequest): Promise<AlertParameterT> => {
  const res = await axios.put<AlertParameterT>(`/devices/${deviceID}/parameters`, body);
  return res.data;
}

export const getDeviceCurrentData = async (deviceID: string): Promise<DeviceCurrentData> => {
  const res = await axios.get<DeviceCurrentData>(`/devices/${deviceID}/current-data`);
  return res.data;
}

export const getDeviceAveragedData = async (deviceID: string): Promise<CurrentData[]> => {
  const res = await axios.get<CurrentData[]>(`/devices/${deviceID}/averaged-data`);
  return res.data;
}

export const getDevicePerformanceSummary = async (deviceID: string, startDate: string, endDate: string): Promise<{ deviceAverages: DeviceMonthlyPerformanceSummary[], deviceCustomerData: CustomerData[] }> => {
  const res = await axios.get<{ deviceAverages: DeviceMonthlyPerformanceSummary[], deviceCustomerData: CustomerData[] }>(`/devices/${deviceID}/monthly-performance`, { params: { startDate, endDate } });
  return res.data;
}

export const generatePerformanceModel = async (deviceID: string): Promise<{ message: string }> => {
  const res = await axios.put<{ message: string }>(`/devices/${deviceID}/performance-models`);
  return res.data;
}

export const getPerformanceModels = async (deviceID: string): Promise<PerformanceModel[]> => {
  const res = await axios.get<PerformanceModel[]>(`/devices/${deviceID}/performance-models`);
  return res.data;
}

export const deletePerformanceModel = async (deviceID: string, modelDate: string): Promise<{ message: string }> => {
  const res = await axios.delete<{ message: string }>(`/devices/${deviceID}/performance-models/${modelDate}`);
  res?.data?.message && showNotification({ message: res.data.message });
  return res.data;
}

export const getWeatherStationCurrentData = async (deviceID: string): Promise<WeatherStationCurrentData> => {
  const res = await axios.get<WeatherStationCurrentData>(`/devices/${deviceID}/weather-station/current-data`);
  return res.data;
}

export const getDeviceCharts = async (deviceID: string, start: string, end: string): Promise<DeviceCharts | PressureMonitorCharts | WeatherStationCharts> => {
  const res = await axios.get<DeviceCharts | PressureMonitorCharts | WeatherStationCharts>(`/devices/${deviceID}/charts`, { params: { start, end } });
  return res.data;
}

export const getDeviceBatteryChart = async (deviceID: string, startDate: string, endDate: string): Promise<ChartItem[]> => {
  const res = await axios.get<ChartItem[]>(`/devices/${deviceID}/charts/battery`, { params: { startDate, endDate } });
  return res.data;
}

export const getDeviceBatteryLevel = async (deviceID: string): Promise<DeviceSoC | undefined> => {
  const res = await axios.get<DeviceSoC | undefined>(`/devices/${deviceID}/battery`);
  return res.data;
}

export const downloadDeviceSamples = async (deviceID: string, start: string, end: string): Promise<void> => {
  const res = await axios.get(`/devices/${deviceID}/download`, { params: { start, end }, responseType: "blob" });
  if(res.data) {
    saveAs(res.data, `device-${deviceID}-${new Date().toLocaleString()}.csv`);
    showNotification({ message: "Downloaded successfully" });
  }
}

export const getDeviceSummary = async (deviceID: string): Promise<DeviceSummary> => {
  const res = await axios.get<DeviceSummary>(`/devices/${deviceID}/summary`);
  return res.data;
}

export const getDeviceServiceView = async (deviceID: string): Promise<{ calibration?: SampleSummary, leakTest?: SampleSummary }> => {
  const res = await axios.get<{ calibration?: SampleSummary, leakTest?: SampleSummary }>(`/devices/${deviceID}/service-view`);
  return res.data;
}

export const getDeviceLogs = async (deviceID: string, startDate?: string): Promise<DeviceLogResponse> => {
  const res = await axios.get<DeviceLogResponse>(`/devices/${deviceID}/logs`, { params: { startDate } });
  return res.data;
}

export const updateDeviceApisID = async (deviceID: string, apisID: string): Promise<ApisDeviceT> => {
  const res = await axios.put<ApisDeviceT>(`/devices/${deviceID}/apisID/${apisID}`);
  return res.data;
}

export const getDeviceAlertHistory = async (deviceID: string): Promise<AlertHistory[]> => {
  const res = await axios.get<AlertHistory[]>(`/devices/${deviceID}/alert-history`);
  return res.data;
}

export const downloadAlertHistory = async (deviceID: string, start: string, end: string): Promise<void> => {
  const res = await axios.get(`/devices/${deviceID}/alert-history/download`, { params: { start, end }, responseType: "blob" });
  if(res.data) {
    saveAs(res.data, `device-alert-history-${deviceID}-${new Date().toLocaleString()}.csv`);
    showNotification({ message: "Downloaded successfully" });
  }
}

export const testControlAlgorithm = async (body: ControlAlgorithmTestRequest): Promise<ControlAlgorithmLog> => {
  const res = await axios.post<ControlAlgorithmLog>("/devices/control-algorithm/test", body);
  return res.data;
}

export const getDeviceCalibrationData = async (deviceID: string): Promise<{ before?: CalibrationData, after: CalibrationData, status: boolean }> => {
  const res = await axios.get<{before?: CalibrationData, after: CalibrationData, status: boolean}>(`/devices/${deviceID}/calibration/data`);
  return res.data;
}

export const getDeviceZeroCalibrationData = async (deviceID: string): Promise<{ before?: CalibrationData, after: CalibrationData, status: boolean }> => {
  const res = await axios.get<{before?: CalibrationData, after: CalibrationData, status: boolean}>(`/devices/${deviceID}/zero-calibration/data`);
  return res.data;
}

export const getDeviceCalibrationLog = async (deviceID: string): Promise<{ prev: CalibrationLog, curr: CalibrationLog, factory: CalibrationLog }> => {
  const res = await axios.get<{ prev: CalibrationLog, curr: CalibrationLog, factory: CalibrationLog }>(`/devices/${deviceID}/calibration/log`);
  return res.data;
}

export const startDeviceCalibration = async (deviceID: string): Promise<{ message: string }> => {
  const res = await axios.post<{ message: string }>(`/devices/${deviceID}/calibration`);
  return res.data;
}

export const startDeviceZeroCalibration = async (deviceID: string): Promise<{ message: string }> => {
  const res = await axios.post<{ message: string }>(`/devices/${deviceID}/zero-calibration`);
  return res.data;
}

export const restoreDeviceCalibration = async (deviceID: string): Promise<{ message: string }> => {
  const res = await axios.post<{ message: string }>(`/devices/${deviceID}/calibration/restore`);
  return res.data;
}

export const setDeviceCanisterContent = async (deviceID: string, body: UpdateDeviceCanisterContentRequest): Promise<ApisDeviceT> => {
  const res = await axios.put<ApisDeviceT>(`/devices/${deviceID}/calibration/canister-content`, body);
  return res.data;
}

export const setDeviceCalibrationScheduler = async (deviceID: string, body: UpdateDeviceCalibrationSchedulerRequest): Promise<ApisDeviceT> => {
  const res = await axios.put<ApisDeviceT>(`/devices/${deviceID}/calibration/scheduler`, body);
  return res.data;
}

export const getValveAdjustmentHistory = async (deviceID: string, date?: string): Promise<ValveHistory[]> => {
  const res = await axios.get<ValveHistory[]>(`/devices/${deviceID}/adjust-valve`, { params: { date } });
  return res.data;
}

export const getDeviceHistoricalData = async (deviceID: string, date?: string): Promise<DeviceSample[]> => {
  const res = await axios.get<DeviceSample[]>(`/devices/${deviceID}/samples`, { params: { date } });
  return res.data;
}

export const updateSampleVisibility = async (deviceID: string, date: string, body: UpdateSampleVisibilityRequest): Promise<DeviceSample> => {
  const res = await axios.put<DeviceSample>(`/devices/${deviceID}/samples/${date}/visibility`, body);
  return res.data;
}

export const getDeviceReports = async (deviceID: string, type: AsyncOperationReportType, start: string, end: string): Promise<AsyncOperationDeviceReport[]> => {
  const res = await axios.get<AsyncOperationDeviceReport[]>(`/devices/${deviceID}/device-reports`, { params: { reportType: type, startDate: start, endDate: end } });
  return res.data;
}

export const getDeviceReport = async (deviceID: string, type: AsyncOperationReportType, date: string): Promise<{ message: string } | { url: string, name: string }> => {
  const res = await axios.get<{ message: string } | { url: string, name: string }>(`/devices/${deviceID}/device-report`, { params: { reportType: type, reportDate: date } });
  if((res.data as { message: string }).message) {
    showNotification({ message: (res.data as { message: string }).message });
  } else {
    const { url, name } = res.data as { url: string, name: string };
    saveAs(url, name);
    showNotification({ message: "Downloaded successfully" });
  }
  return res.data;
}
